import React from 'react';
import logo from './components/assets/background.png';
import vetores from './components/assets/vetores.svg';
import Forms from './components/forms';
import HowItWorks from './components/howItWorks';
import Footer from './components/footer';
import startupLogo from './components/assets/Premiersoft_Startups.png';

import './App.css';

function App() {
  return (
    <div className="App">
      <div className="top-header">
        <img src={vetores} alt="" />
      </div>
      <div className="home-image">
        <img src={logo} alt="" />
      </div>
      <div className="header">
        <img src={startupLogo} alt={""} className={"startupLogo"}/>

        <p>
        Acreditamos que o empreendedorismo é uma ferramenta para transformar vidas e colaborar com a sociedade. Com isso, a Premiersoft tomou a iniciativa de mentorear novos negócios no mercado, repassando a expertise e excelência em inovação.
        </p>
        <h3>INSCREVA-SE NO PROGRAMA!</h3>
      </div>
      <Forms />
      <HowItWorks />
      <Footer />
    </div>
  );

}

export default App;
