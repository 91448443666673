import React, { useRef, useState } from "react";
import "./style.css";
import Input from "../input";
import sendAnswer from "../../services/api";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from "react-toastify";
//import loading from '../assets/loading.gif';


interface formResponse {
  name?: string;
  birth?: string;
  cellphone?: string;
  phone?: string;
  email?: string;
  linkedin?: string;
  schollarship?: string;
  courses?: string;
  talent?: string;
  motivation?: string;
  indication?: string;
  about?: string;  
}

const Forms = () => {
  const [data, setData] = useState<formResponse>();
  const [isLoading, setisLoading] = useState(false);
  const nameInput = useRef<HTMLInputElement>(null);

  const handleChangeInput = (e: { target: { name: any; value: any; }; }) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const checkFields = (fields: formResponse | undefined) => {
    if (!fields) {
      return false;
    }
    const {
        name,
        birth,
        cellphone,
        email,
        linkedin,
        schollarship,
        courses,
        talent,
        motivation,
        indication,
        about,
    } = fields;

    if (
        name &&
        birth &&
        cellphone &&
        email &&
        linkedin &&
        schollarship &&
        courses &&
        talent &&
        motivation &&
        indication &&
        about
    ) {
      return (
        name.length > 0 &&
        birth.length > 0 &&
        cellphone.length > 0 &&
        email.length > 0 &&
        linkedin.length > 0 &&
        schollarship.length > 0 &&
        courses.length > 0 &&
        talent.length > 0 &&
        motivation.length > 0 &&
        indication.length > 0 &&
        about.length > 0
      );
    }
    
    return false;
  };

  const handleSubmit = async () => {
    if (checkFields(data)) {
      setisLoading(true);
      const ret = await sendAnswer(JSON.stringify(data));
      
      if (ret) toast.success("ENVIADO COM SUCESSO! SUAS RESPOSTAS SERÃO ANALISADAS POR NOSSA EQUIPE E MANTEREMOS CONTATO!");
      setisLoading(false);
    } else {
      console.log('error');
      
      toast.error("Preencha todos os campos.");
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className="form">
        <Input
          onChange={handleChangeInput}
          name="name"
          type={"text"}
          placeholder={"Nome completo"}
          nameRef={nameInput}
        />
        <Input
          onChange={handleChangeInput}
          name="birth"
          type={"date"}
          placeholder={"Data de nascimento"}
          nameRef={nameInput}
        />
        <div className={"double-input"}>
          <Input
            onChange={handleChangeInput}
            name="cellphone"
            type={"tel"}
            placeholder={"Celular"}
            className="half"
            mask="(99) 99999-9999"
          />
          <Input
            onChange={handleChangeInput}
            name="phone"
            type={"tel"}
            placeholder={"Telefone"}
            className="half"
            mask="(99) 9999-9999"
          />
        </div>
        <Input
          onChange={handleChangeInput}
          name="email"
          type={"email"}
          placeholder={"E-mail"}
        />
        <Input
          onChange={handleChangeInput}
          name="linkedin"
          type={"text"}
          placeholder={"LinkedIn"}
        />
      </div>
      <h3>FALE MAIS SOBRE VOCÊ</h3>
      <div className="form">
        <Input
          onChange={handleChangeInput}
          name="schollarship"
          type={"textarea"}
          placeholder={
            "Qual sua escolaridade? Como foi e o que você mais gostou?"
          }
        />
        <Input
          onChange={handleChangeInput}
          name="courses"
          type={"textarea"}
          placeholder={
            " Possui curso(s) complementar(es)? Nos conte o porquê fez!"
          }
        />
        <Input
          onChange={handleChangeInput}
          name="talent"
          type={"textarea"}
          placeholder={
            "Qual seu talento natural? Aquilo que você é bom naturalmente, nem parece que precisas aprender e já sabes fazer!"
          }
        />
        <Input
          onChange={handleChangeInput}
          name="motivation"
          type={"textarea"}
          placeholder={
            "O que te motivou a chegar até aqui e vir falar com a gente?"
          }
        />
        <Input
          onChange={handleChangeInput}
          name="indication"
          type={"textarea"}
          placeholder={
            "Você conhece alguém da Premiersoft ou foi indicado por alguém? Se sim, pode nos dizer quem?"
          }
        />
      </div>
      <h3>FALE SOBRE O SEU NEGÓCIO</h3>
      <div className="form">
        <Input
          name="about"
          onChange={handleChangeInput}
          type={"textarea"}
          placeholder={
            "Nos conte em no máximo 1000 caracteres sua ideia de negócio."
          }
          rows={11}
        />
      </div>
      <div className="form" style={{ textAlign: "center" }}>
        <button onClick={() => handleSubmit()} className="send" disabled={isLoading}>
          ENVIAR CADASTRO
          </button>
      </div>
    </div>
  );
};

export default Forms;
