import React, { useRef } from 'react';
import InputMask from 'react-input-mask';
import './style.css';

interface inputProps {
    placeholder: string;
    type: string;
    rows?: number;
    name: string;
    mask?: string;
    className?: string;
    onChange: (e: {
        target: {
            name: any;
            value: any;
        };
    }) => void;
    nameRef?: React.RefObject<HTMLInputElement>;
}
function Input({placeholder, type, rows, name, onChange, className, mask, nameRef}: inputProps) {
    const birthInput = useRef<HTMLInputElement>(null);

    if (type === "textarea") {
        return (
            <textarea name={name} placeholder={placeholder} onChange={onChange} rows={rows || 3} cols={50} maxLength={rows ? 1000 : 300}>
            </textarea>
        )
    } else {
        return (mask) ?
            <InputMask mask={mask} className={className} type={type} name={name} placeholder={placeholder} onChange={onChange}/>
            :
            (type!=='date' ?
            <input className={className} type={type} name={name} placeholder={placeholder} onChange={onChange} ref={nameRef}/>
            :
            <input
                className={className}
                type={'text'}
                ref={birthInput}
                onFocus={async (e) => {
                    if (e.target.type === 'text') {
                        e.target.type = "date";
                        if(birthInput.current && nameRef?.current) {
                            birthInput.current.blur();
                            nameRef.current.focus()
                            //nameRef.current.blur();


                            birthInput.current.focus();
                        }
                    }
                }}
                name={name}
                placeholder={placeholder}
                onChange={onChange}/>
            )
            
    }
}

export default Input;