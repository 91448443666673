import React from 'react';

import './style.css';
import israel from '../assets/israel.png';
import ps from '../assets/premiersoft.svg'
import ciblu from '../assets/ciblu.png';
import cocriar from '../assets/cocriar.svg';
import gene from '../assets/gene.png';
import scangels from '../assets/scangels.png';
import vector1 from '../assets/vector1.svg';
import vector2 from '../assets/vector2.svg';

function Footer() {
    return (
        <div className="footer">
            <img src={ps} alt="Premiersoft" className="ps"/>

            <h2>APOIADORES</h2>
            <div className="apoiadores">
                <img src={cocriar} className="wide cocriar" alt="cocriar" style={{width: "20%"}}/>
                <img src={ciblu} className="wide" alt="" style={{width: "22%"}}/>
                <img src={scangels} className="wide" alt="" style={{width: "18%"}}/>
                <img src={israel} className="square" alt="" style={{width: "10%"}}/>
                <img src={gene} className="square" alt="" style={{width: "10%"}}/>
            </div>

            <p>Todos os direitos reservados a Premiersoft 2022  |  Veja as nossa políticas de privacidade</p>
            <img src={vector2} className="bottom1" alt="" />
            <img src={vector1} className="bottom2" alt="" />
        </div>
    )
}

export default Footer;