import axios from "axios";
import { toast } from "react-toastify";

const api = axios.create({
    baseURL: "https://startups.api.premiersoft.net/",
});

const sendAnswer = async (answers: string)  => {
    const ret = await api.post("/", answers, {
        headers: {
            'Content-Type': 'application/json'
        }
    }).catch(function (error) {
        toast.error(error.response.data);  
    });
    
    return ret;
};

export default sendAnswer;